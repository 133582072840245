
const callouts = [
    {
      name: 'Pet',
      description: 'Luke is having a lot of fun!',
      imageSrc: `${require('../assets/img/luke.jpg')}`,
      imageAlt: 'Luke the Shih Tzu',
      href: '#',
    },
    {
      name: 'Family',
      description: 'Photoshooting session with my family.',
      imageSrc: `${require('../assets/img/family.jpg')}`,
      imageAlt: 'Family',
      href: '#',
    },
    {
      name: 'Travel',
      description: 'Enjoying the view in Santorini.',
      imageSrc: `${require('../assets/img/santorini.jpg')}`,
      imageAlt: 'Santorini',
      href: '#',
    },
  ]
  
  export default function Gallery() {
    return (
      <div className="bg-indigo-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-100">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-200">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  