import './App.css';
import NavBar from '../src/components/NavBar';
import Home from '../src/pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <Router>
      <div>
        <div>
        <NavBar />
          <Routes>
            <Route exact path='/' element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
