export default function Footer() {
  return (
    <div className="bg-indigo-600">
      <div className="py-6 px-3 sm:px-6 lg:px-8">
        <div className="flex">
          <div className="flex flex-1 justify-center text-white">
            <p className="font-medium">
              <span className="md:hidden">&copy; 2023 Jan M. Rosado</span>
              <span className="hidden md:inline">&copy; 2023 Jan M. Rosado. All Rights Reserved.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
