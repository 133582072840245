import * as React from "react";
import { Link } from "react-router-dom";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline'


const solutions = [
  {
    name: 'About',
    href: '#about',
    icon: UserCircleIcon,
  },
  {
    name: 'Contact',
    href: '#contact',
    icon: EnvelopeIcon,
  }
]

export default function NavBar() {
  return (
    <Popover className="relative bg-indigo-600">
      <div className="mx-auto max-w-7xl px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10" style={{borderStyle: "none"}}>
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Your Company</span>
              <h2 className="text-2xl font-bold text-gray-200">JanRosado</h2>
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-gray-200 hover:bg-gray-100 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <a href="#about" className="text-base font-medium text-gray-200 hover:text-gray-100">
              About
            </a>
            <a href="#contact" className="text-base font-medium text-gray-200 hover:text-gray-100">
              Contact
            </a>
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden" style={{zIndex: 1000}}>
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-indigo-600 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                <h2 className="text-2xl font-bold text-gray-200">JanRosado</h2>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-gray-200 hover:bg-gray-100 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon className="h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-200">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
