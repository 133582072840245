

export default function Header() {
  return (
    <>
    <div className="bg-white py-24 sm:py-32 lg:py-40" style={{
      backgroundImage: `url(${require('../assets/img/cartoon-landscape.png')})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "none"
    }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <h2 className="text-lg font-semibold leading-8 text-indigo-600">Full Stack Developer</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Jan Marco Rosado Ortiz</p>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
            Freelance Web Developer. Creating <b>Static Websites</b> for small/medium businesses in Puerto Rico.
            For more information contact me <a href="#contact" className='underline'>here</a>.
          </p>
        </div>
      </div>
    </div>
    </>
  )
}
