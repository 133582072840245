import { AcademicCapIcon, ClockIcon, GlobeAmericasIcon, FaceSmileIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Academics',
    description:
      'Master of Science in Computer Information Systems - Concentration on Web Application Development.',
    icon: AcademicCapIcon,
  },
  {
    name: 'Experience',
    description:
      '2+ years of experience in Web Development.',
    icon: ClockIcon,
  },
  {
    name: 'Hobbies',
    description:
      'Traveling and exploring new places is my favorite hobby!',
    icon: FaceSmileIcon,
  },
  {
    name: 'Born',
    description:
      'Born and raised in Bayamon, Puerto Rico.',
    icon: GlobeAmericasIcon,
  },
]

export default function Feature() {
    return (
        <div className="bg-white py-8 sm:py-16 lg:py-24 bg-gray-50" id='about'>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-2xl font-bold text-gray-900">About</h2>
            <p className="mt-2 text-base leading-7 text-gray-600">A few details about me.</p>
                <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
                    <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                            {features.map((feature) => (
                            <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                            <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0">
                                <feature.icon className="h-8 w-8" aria-hidden="true" />
                            </div>
                            <div className="sm:min-w-0 sm:flex-1">
                                <p className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                                <p className="mt-2 text-base leading-7 text-gray-600">{feature.description}</p>
                            </div>
                            </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
  }
