export default function CTASection() {
    return (
      <div className="bg-white" id="contact">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 lg:flex lg:items-center lg:justify-between py-8 sm:py-16 lg:py-24">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Looking for a static website for your business?</span>
            <span className="block text-indigo-600 underline">Contact me via LinkedIn.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://www.linkedin.com/in/jan-m-rosado-177623164/"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  