import Header from "../components/Header"
import Feature from "../components/Feature"
import Gallery from "../components/Gallery"
import CTASection from '../components/CTASection'
import Footer from "../components/Footer"

export default function Home() {
    return (
        <>
        <Header />
        <Feature />
        <Gallery />
        <CTASection />
        <Footer />
        </>
    )
}